import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
            marginBottom: theme.spacing(2),
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        cardLine: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: theme.spacing(2),
        },
        spacing: {
            padding: theme.spacing(1),
        },
        lastCard: {
            maxWidth: '30vw',
        },
        successTextColor: {
            color: theme.palette.success.main,
        },
        container: {
            marginBottom: theme.spacing(2),
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            alignItems: 'flex-end',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(0),
            },
        },
        cardValuePositive: {
            color: theme.palette.success.main,
        },
        cardValueNegative: {
            color: theme.palette.error.main,
        },
        cardItem: {
            flexGrow: 1,
        },
        secondaryTextColor: {
            color: theme.palette.secondary.main,
        },
        errorTextColor: {
            color: theme.palette.error.main,
        }
    })
);