import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { ChangeRiskManagement } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { lineSpacing } from '../../../../theme/spacingBetweenComponents';
import { IFinancialInfo } from '../../../toolbarInfo/types';
import { ICustomerPageProps } from '../../types';
import Corrections from '../corrections/corrections';
import EmergencySavings from '../emergencySavings/emergencySavings';
import Insurances from '../insurances/insurances';
import InventoryFreeAssets from '../inventoryFreeAssets/inventoryFreeAssets';
import LifeQuality from '../lifeQuality/lifeQuality';
import ProtectionNeeded from '../protectionNeeded/protectionNeeded';
import SuccessionCost from '../successionCost/successionCost';
import SuggestionCardPension from '../suggestionCardPension/suggestionCardPension';
import { IRiskManagement } from '../types';
import formatter from '../../../global/formater';
import { useStyles } from './style'
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';

const RiskManagement: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [, setErr] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const { rentability, riskManagement, successionWithdraw, personalData, pension } = useSelector((store: IStore) => ({
        rentability: store.customer.rentability,
        riskManagement: store.customer.riskManagement,
        successionWithdraw: store.customer.successionWithdraw,
        personalData: store.planning.customerFormValues.customerInfo.personalInfo.personalInfo,
        pension: store.planning.customerFormValues.patrimony.pensions,
    }));

    console.log('PERSONAL DATA', personalData, '\nPENSION', pension, '\nRISKMANAGEMENT', riskManagement)

    let userIDCompare;

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    React.useEffect(() => {

        const loadLocalStorage = async () => {
            userIDCompare = String(localStorage.getItem("userID"));

            if (localStorage.getItem("riskManagement" + userIDCompare) != undefined || localStorage.getItem("riskManagement" + userIDCompare) != null) {

                dispatch(ChangeRiskManagement(JSON.parse(String(localStorage.getItem("riskManagement" + userIDCompare)))));
                setLoading(false);

                let rentabilityValue = rentability;

                if (!rentability) {
                    rentabilityValue = await getDefaultRentability();
                }

                let url = `/riskmanagement?rentability=${rentabilityValue}`;
                if (userID) {
                    url += `&userID=${userID}`;
                }
                if (successionWithdraw) {
                    url += `&quality=${successionWithdraw}`;
                } else {
                    //setLoading(true);
                }

                const response = (await api.get<IRiskManagement>(url)).data;

                if (String(localStorage.getItem("riskManagement" + userIDCompare)) !== JSON.stringify(response)) {
                    localStorage.setItem("riskManagement" + userIDCompare, JSON.stringify(response));
                    dispatch(ChangeRiskManagement(response));
                }

            } else {
                let rentabilityValue = rentability;

                if (!rentability) {
                    rentabilityValue = await getDefaultRentability();
                }

                let url = `/riskmanagement?rentability=${rentabilityValue}`;
                if (userID) {
                    url += `&userID=${userID}`;
                }
                if (successionWithdraw) {
                    url += `&quality=${successionWithdraw}`;
                } else {
                    setLoading(true);
                }

                const response = (await api.get<IRiskManagement>(url)).data;
                localStorage.setItem("riskManagement" + userIDCompare, JSON.stringify(response));
                dispatch(ChangeRiskManagement(response));
            }
            setLoading(false);
        };

        const getDefaultRentability = async () => {
            try {
                const response = await api.get<IFinancialInfo>('/financialinfo');
                return response.data.profileGain.default;
            } catch (e) {
                console.error(e);
                return 0;
            }
        };

        loadLocalStorage();

    }, [successionWithdraw, dispatch, rentability, setLoading]);

    const diff = {
        current: riskManagement.protectionNeeded.current.lifeQuality,
        future: riskManagement.protectionNeeded.future.lifeQuality,
    };

    const rendaAnualBruta = personalData?.annualGrossIncome;
    let limiteRendaDeduzida = Number(rendaAnualBruta) * 0.12;
    let aporteLimiteMes = limiteRendaDeduzida / 12;

    let somaSobrasAnuais = 0;

    [...Array(riskManagement.table.planningDuration)].map((_, i) => (
        i < 11
            ? somaSobrasAnuais += riskManagement.table.contributionProjection[i]
            : null
    ))

    console.log('somaSobrasAnuais', somaSobrasAnuais)

    let mediaSobrasAnuais = (somaSobrasAnuais / 12);
    let valorSugeridoAno = (mediaSobrasAnuais * 0.35);
    let valorSugeridoMes = (valorSugeridoAno / 12);

    console.log('mediaSobrasAnuais', mediaSobrasAnuais,
        'valorSugeridoAno', valorSugeridoAno,
        'valorSugeridoMes', valorSugeridoMes,
    )

    //let valorAnualPGBL = 0;
    let aporteMensalPGBL = 0;

    //let valorAnualVGBL = 0;
    let aporteMensalVGBL = 0;

    for (let i = 0; i < pension.length; i++) {
        if (pension[i].type === 'pgbl') {
            //valorAnualPGBL += pension[i].balance;
            aporteMensalPGBL += pension[i].monthlyContribution
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            null;
        }
        if (pension[i].type === 'vgbl') {
            //valorAnualVGBL += pension[i].balance;
            aporteMensalVGBL += pension[i].monthlyContribution
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            null;
        }
    }

    let rate = 0.03;
    let nper = riskManagement.table.planningDuration;
    let valorAuxpmt = 0;

    if (valorSugeridoAno > 0) {
        if (aporteMensalPGBL < aporteLimiteMes) {
            if (valorSugeridoMes <= (aporteLimiteMes - aporteMensalPGBL)) {
                valorAuxpmt = valorSugeridoMes + aporteMensalPGBL;
            } else {
                valorAuxpmt = ((aporteLimiteMes - aporteMensalPGBL) + aporteMensalPGBL);
            }
        } else {
            valorAuxpmt = 0;
        }
    } else {
        valorAuxpmt = 0;
    }

    let pmt = ((-0.275 * valorAuxpmt) * 12);
    let pv = 0;

    function valorAteIF(rate: number, nper: number, pmt: number, pv: number) {

        let fv_value = 0;

        if (nper === 0) {
            return (0);
        }
        if (rate === 0) { // Interest rate is 0
            fv_value = -(pv + (pmt * nper));
        } else {
            let x = Math.pow(1 + rate, nper);
            fv_value = - (-pmt + x * pmt + rate * x * pv) / rate;
        }

        return fv_value;
    }

    let valorAteIndependenciaFinanceira = valorAteIF(rate, nper, pmt, pv);

    console.log('valorSugeridoAno', valorSugeridoAno, 'rendaAnualBruta', rendaAnualBruta, 'aporteMensalPGBL', aporteMensalPGBL, 'aporteLimiteMes', aporteLimiteMes, 'valorSugeridoMes', valorSugeridoMes)

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <EmergencySavings {...riskManagement.emergencySavings} />
                {lineSpacing()}
                <LifeQuality
                    diff={diff.current}
                    {...riskManagement.lifeQuality}
                    projection={riskManagement.projection}
                />
                {lineSpacing()}
                <SuccessionCost {...riskManagement.successionCosts} />
                {lineSpacing()}
                <InventoryFreeAssets {...riskManagement.inventoryFreeAssets} />
                {lineSpacing()}
                <ProtectionNeeded
                    diff={diff}
                    freeAssets={riskManagement.inventoryFreeAssets}
                    {...riskManagement.protectionNeeded}
                    totalInvested={riskManagement.lifeQuality.totalInvested}
                />
                {lineSpacing()}
                <Corrections {...riskManagement.corrections} />
                {lineSpacing()}
                <Insurances {...riskManagement.contracted} />
                {lineSpacing()}
                <Typography className={classes.subHeading} variant="h5">
                    Sugestões de Previdência
                </Typography>
                <div>
                    <div className={classes.cardLine}>
                        <div style={{ marginRight: '15px' }}>
                            <SuggestionCardPension
                                className={classes.cardItem}
                                header="PGBL já existente"
                                value={
                                    formatter.format(aporteMensalPGBL)
                                }
                                disabled={false}
                                typographyClassName={
                                    classes.secondaryTextColor
                                }
                                onClick={() => { }}
                                subtitle="/Mês"
                            />
                        </div>
                        <div style={{ marginRight: '15px' }}>
                            <SuggestionCardPension
                                className={classes.cardItem}
                                header="VGBL já existente"
                                value={
                                    formatter.format(aporteMensalVGBL)
                                }
                                disabled={false}
                                typographyClassName={
                                    classes.secondaryTextColor
                                }
                                onClick={() => { }}
                                subtitle="/Mês"
                            />
                        </div>
                        <SuggestionCardPension
                            className={classes.cardItem}
                            header="Renda anual tributável"
                            value={
                                formatter.format(Number(rendaAnualBruta))
                            }
                            disabled={false}
                            typographyClassName={
                                classes.secondaryTextColor
                            }
                            onClick={() => { }}
                        />
                    </div>
                </div>
                <Typography variant="h5" style={{ marginTop: '12px' }} >
                    Com base em sua sobra mensal e nas suas contratações de previdência, essas são as sugestões para aporte mensal
                </Typography>
                <div style={{ marginTop: '15px' }}>
                    <div className={classes.cardLine}>
                        <div style={{ marginRight: '15px' }}>
                            <SuggestionCardPension
                                className={classes.cardItem}
                                header="PGBL"
                                value={
                                    valorSugeridoAno > 0
                                        ? Number(rendaAnualBruta) <= 0
                                            ? aporteMensalPGBL > 0
                                                ? formatter.format(0)
                                                : 'Informe uma renda bruta anual'
                                            : aporteMensalPGBL < aporteLimiteMes
                                                ? valorSugeridoMes <= (aporteLimiteMes - aporteMensalPGBL)
                                                    ? formatter.format(valorSugeridoMes)
                                                    : formatter.format(aporteLimiteMes - aporteMensalPGBL)
                                                : formatter.format(valorSugeridoMes)
                                        : Number(rendaAnualBruta) <= 0
                                            ? 'Informe uma renda bruta anual'
                                            : 'Sem sugestão'
                                }
                                disabled={false}
                                typographyClassName={
                                    valorSugeridoAno > 0
                                        ? Number(rendaAnualBruta) > 0
                                            ? classes.secondaryTextColor
                                            : aporteMensalPGBL <= 0
                                                ? classes.errorTextColor
                                                : classes.secondaryTextColor
                                        : classes.errorTextColor
                                }
                                onClick={() => { }}
                            />
                        </div>
                        <div style={{ marginRight: '15px' }}>
                            <SuggestionCardPension
                                className={classes.cardItem}
                                header="VGBL"
                                value={
                                    valorSugeridoAno > 0
                                        ? Number(rendaAnualBruta) <= 0
                                            ? formatter.format(valorSugeridoMes)
                                            : aporteMensalPGBL < aporteLimiteMes
                                                ? valorSugeridoMes <= (aporteLimiteMes - aporteMensalPGBL)
                                                    ? formatter.format(0)
                                                    : formatter.format(valorSugeridoMes - (aporteLimiteMes - aporteMensalPGBL))
                                                : formatter.format(0)
                                        : aporteMensalVGBL > 0
                                            ? formatter.format(0)
                                            : 'Sem sugestão'
                                }
                                disabled={false}
                                typographyClassName={
                                    valorSugeridoAno > 0
                                        ? Number(rendaAnualBruta) > 0
                                            ? classes.secondaryTextColor
                                            : classes.secondaryTextColor
                                        : classes.errorTextColor
                                }
                                onClick={() => { }}
                            />
                        </div>
                        {valorAteIndependenciaFinanceira === 0
                            ? <Card
                                elevation={3}
                                style={{ flexGrow: 1, backgroundColor: '#023C58', height: '100%' }}
                            >
                                <CardContent>
                                    <Typography className={classes.cardHeader}>
                                        Expectativa de patrimônio na IF com o reinvestimento das restituições do PGBL
                                    </Typography>
                                    <div className={classes.content}>
                                        <Typography
                                            style={{ fontSize: 18, minWidth: 100 }}
                                            className={classes.cardValueNegative}
                                        >
                                            Sem PGBL contratada
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                            : <Card
                                elevation={3}
                                style={{ flexGrow: 1, backgroundColor: '#023C58', height: '100%' }}
                            >
                                <CardContent>
                                    <Typography className={classes.cardHeader}>
                                        Expectativa de patrimônio na IF com o reinvestimento das restituições do PGBL
                                    </Typography>
                                    <div className={classes.content}>
                                        <Typography
                                            style={{ fontSize: 18, minWidth: 100 }}
                                            className={classes.cardValuePositive}
                                        >
                                            {formatter.format(valorAteIndependenciaFinanceira)}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        }
                    </div>
                </div>
            </div>
        </LoadingPage>
    );
};
export default RiskManagement;